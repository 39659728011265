import React from 'react'
import './Section1.css'
import IMG1 from '../../assets/section1-1.webp'

const Section1 = () => {
  return (

    <>
            <div className='section1'>

                <div className='section1-content container'>
                    <div className='section1-left'>
                        <h1>WHO WE ARE</h1>
                        <h2>Quality Care from Quality Experts.</h2>
                        <p>At 33rd Street Recovery, we offer safe, medically supervised detox services for individuals struggling with drug and alcohol addiction. Our personalized treatment approach begins with comprehensive evaluations to ensure each client receives the specialized care they need.
                        <br/><br/>
                        Before starting your detox program, our expert team conducts in-depth assessments to understand your substance use history, mental health conditions, co-occurring disorders, and past treatment experiences. This process allows us to develop a customized detox plan that supports a safe and comfortable recovery journey.
                        </p>

                    </div>
                    <div className='section1-right'>
                        <img src={ IMG1 } alt="Woman seating on a bench" loading="lazy"/>
                    </div>
                </div>
              
            </div>

    </>
  )
}

export default Section1